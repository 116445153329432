import {useEffect} from "react";
import {useImmer} from "use-immer";
import * as Const from "../Const";
import axios from "axios";

function useParcelsWithHulls(sessionId, machines, isEnabled) {

    const initialData = {};

    const [hulls, updateHulls] = useImmer(initialData);

    useEffect(() => {
        if (!sessionId || !machines || !isEnabled) {
            return;
        }

        const cancelTokenSource = axios.CancelToken.source();

        const fetchHulls = async () => {

            const config = {
                withCredentials: true,
                headers: {
                    'Authorization': sessionId,
                },
                cancelToken: cancelTokenSource.token,
            };

            let validMachineNames = Array.isArray(machines) ? machines : [machines];
            validMachineNames = validMachineNames.map(m => m.name).filter(name => Boolean(name));

            // TODO FIXME
            validMachineNames = validMachineNames.filter(name => {
                return !name.startsWith("stage") && !name.startsWith("vpa")
            });
            // validMachineNames = ["mp-robofox-0001", "gcb-koeppl-0002", "rcp-ec130-0001", "rcp-geier-0001", "rcp-niko-0003", "rcp-testbed-0001"];

            const methodId = 0;

            try {
                if (methodId === 0) {
                    const requests = [];

                    for (const machineName of validMachineNames) {
                        const url = `${Const.FIELD_API_ALL_HULLS}/${machineName}`;
                        const req = axios.get(url, config);
                        requests.push(req);
                    }

                    const responses = await Promise.allSettled(requests);
                    console.log(responses);

                    responses.forEach((response) => {
                        if (response.status === 'fulfilled') {
                            const res = response.value;
                            const machineName = res.data['machine'];
                            const parcelsWithMachine = res.data['parcels'].map(p => ({...p, machine: machineName}));
                            updateHulls(draft => {
                                draft[machineName] = parcelsWithMachine;
                            });
                        }
                    });

                } else {
                    for (const machineName of validMachineNames) {
                        try {
                            const url = `${Const.FIELD_API_ALL_HULLS}/${machineName}`;
                            const res = await axios.get(url, { ...config, cancelToken: cancelTokenSource.token });
                            const name = res.data['machine'];
                            const parcelsWithMachine = res.data.parcels.map(p => ({...p, machine: name}));
                            updateHulls(draft => {
                                draft[name] = parcelsWithMachine;
                            });
                        } catch (error) {
                            if (!axios.isCancel(error)) {
                                console.warn('Error fetching data:', error);
                            }
                        }
                    }
                }

            } catch (error) {
                if (axios.isCancel(error)) {
                    console.log(error.message);
                } else {
                    console.error('Error fetching data:', error);
                }
            }
        };

        fetchHulls().then(_ => console.log("useParcelsWithHulls done"));

        return () => {
            console.log("useParcelsWithHulls destroy");
            cancelTokenSource.cancel('component unmounting');
        }

    }, [isEnabled, machines, sessionId, updateHulls]);

    return hulls;
}

export default useParcelsWithHulls
