import React, {useMemo, useState} from "react";
import {Button, Table} from "react-bootstrap";
import {Map, MapFill, SortAlphaDown, SortAlphaUp} from "react-bootstrap-icons";
import FilterInput from "./FilterInput";
import useDictionaryFilter from "../hooks/useDictionaryFilter";


function ParcelRow( {parcel, onParcelClicked, rowClass = ""} ) {

    const name = decodeURIComponent(parcel['parcel_name']);
    return(
        <tr className={rowClass}>
            <td>
                {/*{parcel['machine'] === selectedMachine ? (showOnlyActiveMachine ? <MapFill /> : <Map />) : null}*/}
            </td>
            <td className={"overflow-hidden"} onClick={() => onParcelClicked(parcel)}>
                {/*<span className={"text-truncate"} style={{color:"#ccc"}}>{parcel['field_name']}</span><br />*/}
                <span className={"text-truncate"} title={name}>{name}</span>
                <span className={"text-truncate"} style={{color:"#bbb"}}> ({parcel['machine']})</span>
            </td>
        </tr>
    );
}


function OverviewParcelList( {parcels, parcelFilter, machineFilter, selectedMachineName, selectedFieldName, onParcelClicked, hiddenParcels, onParcelFilterChanged} ) {

    const [sortDirection, setSortDirection] = useState(1);
    const [showOnlyActiveMachine, setShowOnlyActiveMachine] = useState(false);

    const machineParcels = useMemo(() => {
        return Object.values(parcels).flat();
    }, [parcels]);
    
    const activeMachineFilter = useMemo(() => {
        return (selectedMachineName ?? machineFilter).toLowerCase();
    }, [machineFilter, selectedMachineName])
    

    const filteredParcels = useDictionaryFilter(machineParcels, 'parcel_name', parcelFilter);
    const machineFilteredParcels = useDictionaryFilter(filteredParcels, 'machine', selectedMachineName ?? machineFilter);
    // const machineFilteredParcels = filteredParcels;

    const displayedParcels = showOnlyActiveMachine ? machineFilteredParcels : filteredParcels;

    const sortedParcels = useMemo(() => {
        const sortBy = 'parcel_name';
        // const filter = (selectedMachineName ?? machineFilter).toLowerCase();
        // console.log("sorting parcels, machine filter: ", activeMachineFilter);
        return (displayedParcels && displayedParcels.length > 0)
            ? displayedParcels.sort((a, b) => {
                // if (a['field_name'] === selectedFieldName && b['field_name'] !== selectedFieldName) {
                //     return -1;
                // } else if (a['field_name'] !== selectedFieldName && b['field_name'] === selectedFieldName) {
                //     return 1;
                // } else {

                return sortDirection * a[sortBy].localeCompare(b[sortBy]);

                // const aa = a['machine'].toLowerCase().includes(activeMachineFilter);
                // const bb = b['machine'].toLowerCase().includes(activeMachineFilter);
                //
                // if (aa && !bb) {
                //     return -1;
                // } else if (!aa && bb) {
                //     return 1;
                // } else {
                //     return sortDirection * a[sortBy].localeCompare(b[sortBy]);
                // }
            })
            : [];
    }, [displayedParcels, sortDirection]);

    // const selectedParcels = useMemo(() => {
    //     return sortedParcels.filter(p => p['field_name'] === selectedFieldName);
    // }, [selectedFieldName, sortedParcels])

    return (
        <Table className={"table-sm w-100"}>
            <thead>
            <tr>
                <th></th>
                <th className={"w-100"}>
                    <Button className={"w-100 btn-light shadow-none"} onClick={() => setSortDirection(-sortDirection)}>
                        Parcel {sortDirection > 0 ? <SortAlphaDown /> : <SortAlphaUp />}
                    </Button>
                </th>
            </tr>
            <tr>
                <th>
                    <Button className={"flex-grow-0 btn-light shadow-none"} onClick={() => setShowOnlyActiveMachine(!showOnlyActiveMachine)}>
                        {showOnlyActiveMachine ? <MapFill /> : <Map />}
                    </Button>
                </th>
                <th>
                    <FilterInput filter={parcelFilter} onFilterChanged={s => onParcelFilterChanged(s)} />
                </th>
            </tr>
            {/*{selectedParcels*/}
            {/*    ? selectedParcels.map(p => <ParcelRow key={"selected_" + p['machine'] + p['field_name']} parcel={p} onParcelClicked={onParcelClicked} rowClass={"table-primary"} />)*/}
            {/*    : null*/}
            {/*}*/}
            </thead>
            <tbody>
            {sortedParcels
                ? sortedParcels.map(p => {
                    const rowClass = p['field_name'] === selectedFieldName
                        ? "table-primary"
                        : activeMachineFilter && p['machine'].includes(activeMachineFilter)
                            ? "table-success"
                            : "";
                    return <ParcelRow key={p['machine'] + p['field_name']} parcel={p} onParcelClicked={onParcelClicked} rowClass={rowClass}/>
                })
                : null
            }
            </tbody>
        </Table>
    )
}



export default OverviewParcelList;
