import React, {useEffect, useMemo, useRef, useState} from 'react';
import L from 'leaflet';
import {Button} from "react-bootstrap";
import useDictionaryFilter from "../hooks/useDictionaryFilter";
import ReactDOMServer from 'react-dom/server';
import {calculateAverage, calculateBounds} from "../utils/OwnMath";
import * as Const from "../Const";


const offlineMarkerOptions = { color: 'gray', radius: 4, pane: Const.PANE_NAME_MACHINES };
const currentOnlineMarkerOptions = { color: 'green', radius: 6, pane: Const.PANE_NAME_MACHINES };
const oldOnlineMarkerOptions = { color: 'orange', radius: 6, pane: Const.PANE_NAME_MACHINES };

const hullLinePathOptions = { color: 'orange', interactive: true, pane: Const.PANE_NAME_PARCELS };
const selectedHullLinePathOptions = { color: 'red', interactive: false, pane: Const.PANE_NAME_SELECTED_PARCELS };


function calculateParcelTooltipPosition(parcel) {
    const { min, max } = calculateBounds(parcel['hull']);
    return [min[0], 0.5 * (min[1] + max[1])];
}


function OverviewMapV2({ parcels, parcelFilter, machines, livePositions, navigationSkills, selectedMachineName, selectedParcelName, onMachineClicked, onParcelClicked, onMachinePopupClosed, counter, updateCounter }) {
    console.log("MAP update called");

    const mapRef = useRef(null);
    const markerRefs = useRef([]);
    const parcelRefs = useRef([]);

    const savedSelectedParcelName = useRef(null);


    const [savedSelectedMachineName, setSavedSelectedMachineName] = useState(null);
    const [updates, setUpdates] = useState(0);

    useEffect(() => {
        setUpdates(updates => updates + 1);
    }, [machines, livePositions, navigationSkills, selectedMachineName]);



    const flatParcels = useMemo(() => {
        console.log("MAP", "update flat parcels");
        return parcels ? Object.values(parcels).flat() : [];
    }, [parcels]);

    const selectedParcels = useDictionaryFilter(flatParcels, 'parcel_name', parcelFilter);

    const validParcels = useMemo(() => {
        console.log("MAP", "update valid parcels");
        return selectedParcels ? selectedParcels.filter((parcel) => parcel['hull'].length > 0) : null;
    }, [selectedParcels]);


    useEffect(() => {
        console.log("MAP", "update parcel polygons");

        if (savedSelectedParcelName.current && savedSelectedParcelName.current !== selectedParcelName) {
            if (parcelRefs.current[savedSelectedParcelName.current]) {
                console.log("MAP removing selected parcel", savedSelectedParcelName.current);
                parcelRefs.current[savedSelectedParcelName.current].remove();
                delete parcelRefs.current[savedSelectedParcelName.current];
            }
        }

        validParcels.filter(parcel => parcel.field_name !== selectedParcelName).forEach(parcel => {
            if (!parcelRefs.current[parcel.field_name]) {
                console.log("MAP adding parcel", parcel.field_name);
                const newParcelPolygon = L.polygon(parcel['hull'], hullLinePathOptions);
                newParcelPolygon.on('click', () => { onParcelClicked(parcel); });
                newParcelPolygon.bindTooltip(decodeURIComponent(parcel['parcel_name']), { direction: "bottom", position: calculateParcelTooltipPosition(parcel) })
                newParcelPolygon.addTo(mapRef.current);
                parcelRefs.current[parcel.field_name] = newParcelPolygon;
            }
        })

        if (selectedParcelName && selectedParcelName !== savedSelectedParcelName.current) {
            const parcel = validParcels.find(parcel => parcel.field_name === selectedParcelName);
            if (parcel) {
                console.log("MAP adding selected parcel", parcel.field_name, "hull points:", parcel['hull'].length);
                const newParcelPolygon = L.polygon(parcel['hull'], selectedHullLinePathOptions);
                newParcelPolygon.bindTooltip(decodeURIComponent(parcel['parcel_name']), { direction: "bottom", position: calculateParcelTooltipPosition(parcel) })
                newParcelPolygon.addTo(mapRef.current);
                parcelRefs.current[selectedParcelName] = newParcelPolygon;
            }
        }
    }, [onParcelClicked, selectedParcelName, validParcels]);




    const liveMachines = useMemo(() => {
        return machines ? machines.filter((machine) => Object.keys(livePositions).find(value => value === machine.name)) : null;
    }, [livePositions, machines]);

    const machineMarkerData = useMemo(() => {
        console.log("MAP", "update machine marker data");
        if (liveMachines && livePositions && mapRef.current) {
            return liveMachines.map(m => {
                const pos = livePositions[m.name];

                const quality = pos.positionQuality;
                const markerOptions = m.isOnline
                    ? (quality === 2 ? currentOnlineMarkerOptions : (quality === 1 ? oldOnlineMarkerOptions : offlineMarkerOptions))
                    : offlineMarkerOptions;

                m.id = m.name;
                m.lat = pos.latitude;
                m.lng = pos.longitude;
                m.markerOptions = markerOptions;
                m.tooltip = m.name;
                return m;
            })
        } else {
            return [];
        }
    }, [liveMachines, livePositions]);


    useEffect(() => {
        console.log("MAP", "update machine markers");
        machineMarkerData.forEach(marker => {
            if (!markerRefs.current[marker.id]) {
                // console.log("MAP", "adding marker for", marker.id);
                const newMarker = L.circleMarker([marker.lat, marker.lng], marker.markerOptions);
                newMarker.addTo(mapRef.current);
                newMarker.bindTooltip(marker.tooltip, { direction: "bottom", offset: [0, 15], closeOnEscapeKey: false, autoPan: false });
                newMarker.on('click', () => { onMachineClicked(marker.id) });
                markerRefs.current[marker.id] = newMarker;

            } else {
                // console.log("MAP", "updating marker", marker.id);
                markerRefs.current[marker.id].setLatLng([marker.lat, marker.lng]);
                markerRefs.current[marker.id].setTooltipContent(marker.tooltip);
                markerRefs.current[marker.id].setStyle(marker.markerOptions);
            }
        });

        const newMarkerIds = machineMarkerData.map(marker => marker.id);
        Object.keys(markerRefs.current).forEach(id => {
            if (!newMarkerIds.includes(id)) {
                markerRefs.current[id].remove();
                delete markerRefs.current[id];
            }
        });
    }, [machineMarkerData, onMachineClicked]);
    
    
    useEffect(() => {
        if (selectedMachineName !== savedSelectedMachineName) {
            console.log("MAP 2: selected machine changed", selectedMachineName);

            if (savedSelectedMachineName) {
                const popup = markerRefs.current[savedSelectedMachineName]?.getPopup();
                markerRefs.current[savedSelectedMachineName]?.closePopup();
                markerRefs.current[savedSelectedMachineName]?.unbindPopup();
                popup?.remove();
            }

            if (selectedMachineName) {
                if (markerRefs.current[selectedMachineName]) {
                    console.log("MAP 2: adding popup for", selectedMachineName);

                    let content = selectedMachineName;
                    const navigationSkill = navigationSkills[selectedMachineName];
                    if (navigationSkill) {
                        const element = (
                            <>
                                {/*<button type="button" className="btn-close" aria-label="Close" onClick={() => onMachineClicked(selectedMachineName)}></button>*/}
                                {/*<br />*/}
                                <div><strong>{selectedMachineName}</strong></div>
                                <div>Identifier: {navigationSkill['identifier']}</div>
                                <div>Status: {navigationSkill['status']}</div>
                                <div>Autonomy: {navigationSkill['activateAutonomy']}</div>
                            </>
                        );
                        content = ReactDOMServer.renderToString(element);
                    }

                    markerRefs.current[selectedMachineName]
                        .bindPopup(content, {autoClose: false, closeOnEscapeKey: false, closeButton: false, closePopupOnClick: false, autoPan: false})
                        .openPopup();
                }
            }
            setSavedSelectedMachineName(selectedMachineName);
        }
    }, [navigationSkills, onMachineClicked, onMachinePopupClosed, savedSelectedMachineName, selectedMachineName]);





    useEffect(() => {
        if (!mapRef.current) {
            mapRef.current = L.map('map', {
                center: [49.441437, 7.716687],
                zoom: 18,
                closePopupOnClick: false,
            });

            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                maxZoom: 19,
            }).addTo(mapRef.current);

            const parcelPane = mapRef.current.createPane(Const.PANE_NAME_PARCELS);
            parcelPane.style.zIndex = 410;

            const selectedParcelPane = mapRef.current.createPane(Const.PANE_NAME_SELECTED_PARCELS);
            selectedParcelPane.style.zIndex = 420;

            const machineMarkerPane = mapRef.current.createPane(Const.PANE_NAME_MACHINES);
            machineMarkerPane.style.zIndex = 430;

        } else {
            if (selectedParcelName) {
                if (savedSelectedParcelName.current !== selectedParcelName) {
                    const parcel = validParcels.find(p => p.field_name === selectedParcelName);
                    console.log("MAP selected parcel", parcel);
                    if (parcel) {
                        const { min, max } = calculateBounds(parcel['hull']);
                        const average = calculateAverage([min, max]);
                        console.log("MAP moving to parcel");
                        mapRef.current.setView(average);
                    }
                }
            } else if (selectedMachineName && !selectedParcelName) {
                const pos = livePositions[selectedMachineName];
                console.log("MAP machine pos", pos);
                if (pos) {
                    console.log("MAP moving to machine");
                    mapRef.current.setView([pos.latitude, pos.longitude]);
                }
            }
            savedSelectedParcelName.current = selectedParcelName;
        }
    }, [updateCounter, livePositions, selectedMachineName, selectedParcelName, validParcels]);

    // useEffect(() => {
    //     markersRef.current.forEach(marker => marker.remove());
    //
    //     if (liveMachines && mapRef.current) {
    //         markersRef.current = liveMachines.map(machine => {
    //             const livePosition = livePositions[machine.name];
    //             const quality = livePosition.positionQuality;
    //             const markerOptions = machine.isOnline
    //                 ? (quality === 2 ? currentOnlineMarkerOptions : (quality === 1 ? oldOnlineMarkerOptions : offlineMarkerOptions))
    //                 : offlineMarkerOptions;
    //
    //             const marker = L.circleMarker([livePosition.latitude, livePosition.longitude], markerOptions).addTo(mapRef.current);
    //             marker.on('click', () => {onMachineClicked(machine.name)});
    //
    //             const isPermanent = machine.name === selectedMachineName;
    //             const direction = isPermanent ? "bottom" : "top";
    //             const offset = isPermanent ? [0, 5] : [0, -5];
    //             const tooltipOptions = {permanent: isPermanent, direction: direction, offset: offset, interactive: isPermanent};
    //
    //             const navigationSkill = navigationSkills[machine.name];
    //
    //             if (isPermanent && navigationSkill) {
    //                 const element = (
    //                     <>
    //                         <button type="button" className="btn-close" aria-label="Close" onClick={() => onMachineClicked(machine.name)}></button>
    //                         <br />
    //                         <div><strong>{machine.name}</strong></div>
    //                         <div>Identifier: {navigationSkill['identifier']}</div>
    //                         <div>Status: {navigationSkill['status']}</div>
    //                         <div>Autonomy: {navigationSkill['activateAutonomy']}</div>
    //                     </>
    //                 );
    //                 const content = ReactDOMServer.renderToString(element);
    //                 const tooltip = marker.bindTooltip(content, tooltipOptions);
    //             } else {
    //                 marker.bindTooltip(machine.name, tooltipOptions);
    //             }
    //
    //             return marker;
    //         });
    //     }
    // }, [liveMachines, livePositions, navigationSkills, onMachineClicked, selectedMachineName]);
    //
    // useEffect(() => {
    //     if (validParcels && mapRef.current) {
    //
    //     }
    // }, [validParcels]);

    return (
        <>
            <div><Button onClick={() => updateCounter()} >Updates: {counter} ({updates})</Button></div>
            <div id="map"></div>
        </>
    );
}

export default OverviewMapV2;
