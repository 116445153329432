import React, { Suspense, lazy } from 'react';
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import RootPage from "./RootPage";
import {SessionContextProvider} from "./context/SessionContextProvider";
import * as Const from "./Const";
import MainPage from "./components/MainPage";

const UserLoginPage = lazy(() => import('./components/UserLoginPage'))
const UserHomePage = lazy(() => import('./components/UserHomePage'))
const SuccessPage = lazy(() => import('./components/SuccessPage'))


function App() {

    // noinspection JSUnresolvedVariable
    const isEmbedded = typeof RmAppJavaScriptInterface !== "undefined";
    const language = Const.LANG_EN;

    console.log("is embedded: " + isEmbedded);

    return (
        <SessionContextProvider>
            <BrowserRouter basename={Const.BASE_NAME}>
                <Suspense fallback={<div>Loading...</div>}>
                    <Routes>
                        <Route exact path={"/"} element={<Navigate to="/home" replace={true} />} />
                        <Route exact path={"/home"} element={<MainPage />} />

                        <Route path={"/"} element={<RootPage />}>
                            <Route path={"user"}>
                                <Route path={"login"} element={<UserLoginPage tab={"login"} language={language} isEmbedded={isEmbedded}/>}/>
                                <Route path={"new"} element={<UserLoginPage tab={"register"} language={language} isEmbedded={isEmbedded}/>}/>
                                <Route path={"confirm"} element={<UserLoginPage tab={"confirm"} language={language} isEmbedded={isEmbedded}/>} >
                                    <Route path={":userId"} element={<UserLoginPage tab={"confirm"} language={language} isEmbedded={isEmbedded}/>} />
                                </Route>
                                {/*<Route path={"home"} element={<UserHomePage language={language} isEmbedded={isEmbedded}/>}/>*/}
                                <Route path={"success"} element={<SuccessPage language={language} isEmbedded={isEmbedded}/>}/>
                            </Route>
                        </Route>


                    </Routes>
                </Suspense>
            </BrowserRouter>
        </SessionContextProvider>
    );
}


export default App;
