import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {SessionContext} from "../context/SessionContextProvider";
import useMachines from "../hooks/useMachines";
import useMqtt from "../hooks/useMqtt";
import {parseLocation, parseNavigationSkill, parseStatus} from "../utils/Parsers";
import OverviewMachineList from "./OverviewMachineList";
import useUserSessionInfo from "../hooks/useUserSessionInfo";
import UserHeader from "./UserHeader";
import OverviewParcelList from "./OverviewParcelList";
import useParcelsWithHulls from "../hooks/useParcelsWithHulls";
import packageJson from "../../package.json";
import useMachineFieldBidirectionalMapping from "../hooks/useMachineFieldBidirectionalMapping";
import {useNavigate} from "react-router-dom";
import * as Const from "../Const";
import OverviewMapV2 from "./OverviewMapV2";
import {BASE_NAME} from "../Const";


function MainPage() {

    const version = packageJson.version;

    const { session } = useContext(SessionContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (!session) {
            navigate(Const.BASE_NAME + "/user/login");
        }
    });

    const [sessionId, userId, userName, remoteAddr] = useUserSessionInfo(() => navigate(BASE_NAME + "/user/login"));

    // useEffect(() => {
    //     if (!sessionId) {
    //         navigate(Const.BASE_NAME + "/user/login");
    //     }
    // });

    const machines = useMachines(session);

    const parcelsWithHulls = useParcelsWithHulls(session, machines, true);
    const [hiddenParcels, setHiddenParcels] = useState(new Set());

    const livePositions = useMqtt(session, machines, "%/position", parseLocation);
    const machineStates = useMqtt(session, machines, "%/status", parseStatus);
    const navigationSkills = useMqtt(session, machines, "%/active_navigation_skill", parseNavigationSkill);

    const [machineFilter, setMachineFilter] = useState("");
    const [parcelFilter, setParcelFilter] = useState("");

    const [clicks, setClicks] = useState(0); // TODO remove

    const [selectedMachineName, setSelectedMachineName] = useState(null);
    const [selectedFieldName, setSelectedFieldName] = useState(null);

    const livePositionsRef = useRef({});

    const machineFieldMapping = useMachineFieldBidirectionalMapping(parcelsWithHulls);

    const highlightedMachines = useMemo(() => {
        return machineFieldMapping.getMachineNames(selectedFieldName);
    }, [machineFieldMapping, selectedFieldName])

    useEffect(() => {
        // console.log("updating live position reference, version", livePositions[VERSION_TAG]);
        livePositionsRef.current = livePositions;
    }, [selectedFieldName, selectedMachineName, livePositions]);


    function handleMachineClicked(name) {
        console.log("MAIN machine clicked ", name);
        if (name === selectedMachineName) {
            setSelectedMachineName(null);
        } else {
            setSelectedFieldName(null)
            setSelectedMachineName(name);
        }
    }

    function handleMachinePopupClosed(name) {
        console.log(`MAIN: Machine popup closed: ${name}`);
        if (name === selectedMachineName) {
            setSelectedMachineName(null);
        }
    }

    function handleParcelClicked(parcel) {
        console.log("MAIN: parcel clicked", parcel);
        if (parcel['field_name'] === selectedFieldName) {
            setSelectedFieldName(null);
        }
        else {
            // setCurrentMachineName(null);
            setSelectedFieldName(parcel['field_name']);
        }
    }

    function handleMachineFilterChanged(f) {
        if (selectedMachineName && !selectedMachineName.toLowerCase().includes(f)) {
            setSelectedMachineName(null);
        }
        setMachineFilter(f);
    }

    function handleParcelFilterChanged(f) {
        setParcelFilter(f);
    }

    // function handleParcelVisibilityClicked(parcel) {
    //     const name = parcel['field_name'];
    //     if (hiddenParcels.has(name)) {
    //         hiddenParcels.delete(name);
    //     } else {
    //         hiddenParcels.add(name);
    //     }
    //     setHiddenParcels(new Set(hiddenParcels));
    // }


    // TODO remove
    function updateClicks() {
        let count = 0;
        const intervalId = setInterval(() => {
            if (count < 100) {
                setClicks(prevState => prevState + 1);
                count++;
            } else {
                clearInterval(intervalId);
            }
        }, 100);
    }


    return (
        <div className={"container w-100"}>
            <div className={"mb-5"}>
                <UserHeader userId={userId} userName={userName} remoteAddr={remoteAddr} />
                <div>Version {version}</div>
            </div>
            <div className={"row col-12 sticky-top shadow"}>
                <OverviewMapV2
                    // center={mapCenter}
                               parcels={parcelsWithHulls}
                               hiddenParcels={hiddenParcels}
                               machines={machines}
                               livePositions={livePositions}
                               navigationSkills={navigationSkills}
                               selectedMachineName={selectedMachineName}
                               selectedParcelName={selectedFieldName}
                               parcelFilter={parcelFilter}
                               onParcelClicked={handleParcelClicked}
                               onMachineClicked={(machineName) => handleMachineClicked(machineName)}
                               onMachinePopupClosed={(machineName) => handleMachinePopupClosed(machineName)}
                               counter={clicks}
                               updateCounter={updateClicks}
                />
            </div>
            <div className={"row col-12 my-5"}>
                <div className={"col-5"}>
                    <OverviewMachineList machines={machines}
                                         machineStates={machineStates}
                                         livePositions={livePositions}
                                         selectedMachineName={selectedMachineName}
                                         machineFilter={machineFilter}
                                         highlightedMachines={highlightedMachines}
                                         selectedField={selectedFieldName}
                                         onMachineFilterChanged={filter => handleMachineFilterChanged(filter)}
                                         onMachineClicked={name => handleMachineClicked(name)}
                    />
                </div>
                <div className={"col-7"}>
                    <OverviewParcelList parcels={parcelsWithHulls}
                                        parcelFilter={''}
                                        onParcelFilterChanged={filter => handleParcelFilterChanged(filter)}
                                        hiddenParcels={hiddenParcels}
                                        machineFilter={machineFilter}
                                        selectedMachineName={selectedMachineName}
                                        selectedFieldName={selectedFieldName}
                                        onParcelClicked={parcel => handleParcelClicked(parcel)}
                                        // onParcelVisibilityClicked={handleParcelVisibilityClicked}
                    />
                </div>
            </div>
        </div>
    );


}


export default MainPage;