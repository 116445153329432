import {useEffect, useMemo} from "react";
import {useImmer} from "use-immer";
import mqtt from "precompiled-mqtt";
import * as Const from "../Const";


export const VERSION_TAG = '__version__';
export const TIMESTAMP_TAG = '__timestamp__';


// const initialData = {[VERSION_TAG]: 0};
const initialData = {};
const PERCENT_SIGN_REG_EXP = /^([^%]*%)[^%]*$/;



function useMqtt(sessionId, machines, mqttTopic, dataTransformOperation) {

    const [mqttData, updateMqttData] = useImmer(initialData);

    const validMachineNames = useMemo(() => {
        const namesArray = Array.isArray(machines) ? machines : [machines];
        return namesArray.map(m => m.name).filter(name => Boolean(name));
    }, [machines]);


    useEffect(() => {

        function assertStringContainsPercentSignOnce(inputString) {
            return PERCENT_SIGN_REG_EXP.test(inputString);
        }

        function getReplacedContent(originalString, modifiedString) {
            const index = originalString.indexOf("%");
            const length = modifiedString.length - originalString.length;
            return modifiedString.substring(index, index + length + 1);
        }

        function calculateObjectSizeInBytes(obj) {
            const jsonString = JSON.stringify(obj);
            const encoder = new TextEncoder();
            const encodedData = encoder.encode(jsonString);
            return encodedData.length;
        }

        // TODO get from API
        const mqttOptions = {
            username: 'rm',
            password: '!UasMQTT',
        };

        const mqttClient = mqtt.connect(Const.MQTT_BROKER_ADDRESS, mqttOptions);

        const subscribeLiveData = async () => {
            if (!sessionId || !machines || !mqttTopic) {
                return;
            }

            if (!assertStringContainsPercentSignOnce(mqttTopic)) {
                console.error("mqtt topic must contain exactly one '%'");
                return;
            }

            mqttClient.on('connect', () => {
                console.log("useMqtt connected to MQTT server, subscribing");

                for (const machineName of validMachineNames) {
                    const fullTopic = mqttTopic.replace("%", machineName);
                    // console.log("useMqtt topic: ", fullTopic);

                    mqttClient.subscribe(fullTopic, null, (err) => {
                        if (err) {
                            // console.error(err);
                        }
                    });
                }
            });

            mqttClient.on('message', (topic, messageBuffer) => {
                // console.log(topic, messageBuffer.toString());

                let message = messageBuffer.toString();
                if (dataTransformOperation && typeof dataTransformOperation === 'function') {
                    message = dataTransformOperation(message);
                }

                const machineName = getReplacedContent(mqttTopic, topic);

                updateMqttData((draft) => {
                    // const newEntry = {...message};
                    // newEntry[TIMESTAMP_TAG] = Date.now();
                    //
                    // if (draft[machineName]) {
                    //     // console.log(`topic ${topic} machine ${machineName} known: ${JSON.stringify(draft[machineName])}, message: ${JSON.stringify(message)}`);
                    //     newEntry[VERSION_TAG] = draft[machineName][VERSION_TAG] + 1;
                    // }
                    // else {
                    //     // console.log(`topic ${topic} machine ${machineName} is new, version = 0, message: ${JSON.stringify(message)}`);
                    //     newEntry[VERSION_TAG] = 0;
                    // }
                    //
                    // draft[machineName] = newEntry;
                    // draft[VERSION_TAG]++;

                    draft[machineName] = message;
                    // console.log("MQTT size", calculateObjectSizeInBytes(draft))
                });
            });

        };

        subscribeLiveData().catch(error => console.error("Error in useMqtt:", error));

        
        return () => {
            console.log("useMqtt disconnecting");
            mqttClient.end(true);
        }

    }, [machines, sessionId, mqttTopic, dataTransformOperation, validMachineNames, updateMqttData]);

    return mqttData;
}



export default useMqtt;
