import React, {useContext} from "react";
import * as Const from "../Const";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {SessionContext} from "../context/SessionContextProvider";
import {BASE_NAME} from "../Const";


function UserHeader(props) {

    const navigation = useNavigate();
    const { session, updateSession } = useContext(SessionContext);


    function handleLogout() {
        axios.delete(Const.API_CLOUD_USERS_AUTH)
            .then(res => {
                // localStorage.removeItem(Const.COOKIE_SESSION);
                updateSession(null);
                navigation(BASE_NAME + "/user/login");
            });
    }


    return (
        <div className={"container"}>
            <div className={"row d-flex align-items-center"}>
                <div className={"col-9"}>
                    <div className={""}>{props.userId > 0 ? "logged in" : "not logged in"} {props.userId}: {props.userName} {props.remoteAddr}</div>
                    {/*<div style={{color: "#bbb"}}>{session}</div>*/}
                </div>
                <div className={"col-3"}>
                    <button className={"btn btn-secondary w-100"} onClick={handleLogout}>Log out</button>
                </div>
            </div>
        </div>
    );

}


export default UserHeader;
