const API_BASE = "https://cloud.robotmakers.de/api/";
export const API_CLOUD_USERS = API_BASE + "cloud_users";
export const API_CLOUD_USERS_VERIFY = API_CLOUD_USERS + "/%/verify";
export const API_MACHINES = API_BASE + "machines";
export const API_CLOUD_USERS_AUTH = API_BASE + "auth";
export const API_CLOUD_USERS_AUTH_REL_USER = API_CLOUD_USERS_AUTH + "?rel=user";
export const API_MANAGED_USERS = API_CLOUD_USERS + "/%/managed_users";
export const API_MANAGED_USERS_REL_USER = API_CLOUD_USERS + "/%/managed_users?rel=user";
export const API_MACHINE_ASSIGNED_USERS = API_MACHINES + "/%/assigned_users";
export const API_USERS_DELETE = API_BASE + "users/";

const FIELD_API_BASE = "https://cloud.robotmakers.de/edge-api/"
// const FIELD_API_BASE = "http://192.168.2.10:6544/";
export const FIELD_API_PARCELS = FIELD_API_BASE + "parcels";
export const FIELD_API_TRAJECTORY = FIELD_API_BASE + "trajectory";
export const FIELD_API_ALL_HULLS = FIELD_API_BASE + "hulls";

export const BASE_NAME = "";
export const LANG_EN = 'en';

export const COOKIE_SESSION = 'rm-user';
export const SESSION_ID = 'session-id';

export const MQTT_BROKER_ADDRESS = "wss://mqtt.robotmakers.de:443";

export const PANE_NAME_PARCELS = "parcels";
export const PANE_NAME_SELECTED_PARCELS = "selected_parcels";
export const PANE_NAME_MACHINES = "machines";




