import {useEffect, useState} from "react";
import BidirectionalMapping from "../utils/BidirectionalMapping";


function useMachineFieldBidirectionalMapping(parcelsWithHulls) {

    const [mapping, setMapping] = useState(new BidirectionalMapping())

    useEffect(() => {

        if (parcelsWithHulls) {
            const newMapping = new BidirectionalMapping();
            const machineNames = Object.keys(parcelsWithHulls);

            for (const machineName of machineNames) {
                for (const parcel of parcelsWithHulls[machineName]) {
                     newMapping.add(machineName, parcel['field_name']);
                }
            }
            setMapping(newMapping);

        } else {
            setMapping(new BidirectionalMapping());
        }


    }, [parcelsWithHulls])

    return mapping;

}


export default useMachineFieldBidirectionalMapping;
