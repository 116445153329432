import {useEffect, useState} from "react";


function useDictionaryFilter(dictionary, propertyName, filter) {

    const [filteredDictionary, setFilteredDictionary] = useState([]);

    useEffect(() => {
        if (dictionary && filter) {
            setFilteredDictionary(dictionary.filter(m => m[propertyName].toLowerCase().includes(filter.toLowerCase())));
        } else {
            setFilteredDictionary(dictionary)
        }
    }, [dictionary, filter, propertyName])

    return filteredDictionary;
}


export default useDictionaryFilter;
