import {useEffect, useState} from 'react';
import * as Const from "../Const";
import axios from "axios";
// import {useNavigate} from "react-router-dom";

function useUserSessionInfo(onError) {

    const [sessionId, setSessionId] = useState(null);
    const [userId, setUserId] = useState(null);
    const [userName, setUserName] = useState(null);
    const [remoteAddr, setRemoteAddr] = useState(null);

    useEffect(() => {
        console.log("USE USER SESSION start")
        let isActive = true;
        try {
            console.log("home getting session");
            axios.get(Const.API_CLOUD_USERS_AUTH_REL_USER, {withCredentials: true}).then(res => {
                if (isActive) {
                    const dataJson = res['data'];
                    console.log("session:", dataJson);
                    console.log("session user:", dataJson['user']);
                    console.log("session id:", dataJson['sessionId']);
                    // console.log("session stringify:", JSON.stringify(dataJson));
                    // localStorage.setItem(Const.COOKIE_SESSION, JSON.stringify(dataJson));

                    setSessionId(dataJson['sessionId']);
                    setUserId(dataJson['user_id']);
                    setUserName(dataJson['user']['username']);
                    setRemoteAddr(dataJson['remote_addr']);
                }
            }).catch(error => {
                if (isActive) {
                    console.log("error: ", JSON.stringify(error));
                    onError();
                }
            });
        } catch (exception) {
            if (isActive) {
                console.log("exception: ", JSON.stringify(exception));
                // navigation("/user/login");
            }
        }

        return () => {
            console.log("USE USER SESSION stop")
            isActive = false;
        }

    }, [onError]);


    return [sessionId, userId, userName, remoteAddr];

}

export default useUserSessionInfo