export function parseLocation(jsonStr) {
    // console.log("parse location", jsonStr);
    try {
        const jsonObj = JSON.parse(jsonStr);
        const latitude = jsonObj['latitude'];
        const longitude = jsonObj['longitude'];
        const heading = jsonObj['heading'];

        const positionQualityString = jsonObj['position_quality'];
        const positionQuality = positionQualityString === "good" ? 2 : positionQualityString === "degraded" ? 1 : 0;

        return {latitude, longitude, heading, positionQuality};
    } catch (err) {
        console.error('Invalid JSON:', err);
        return null;
    }
}

export function parseStatus(jsonStr) {
    try {
        return {'status': parseInt(jsonStr)};
    } catch (err) {
        console.error('Invalid JSON:', err);
        return {'status': 0};
    }
}

export function parseNavigationSkill(jsonStr) {
  try {
      const jsonObj = JSON.parse(jsonStr);
      const status = jsonObj["status"];
      const activateAutonomy = jsonObj["activate_autonomy"];
      const identifier = jsonObj["identifier"];
      return {status, activateAutonomy, identifier};
  } catch (err) {
      console.error('Invalid JSON:', err);
      return null;
  }
}