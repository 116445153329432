import {useEffect, useState} from 'react';
import * as Const from "../Const";
import axios from "axios";

function useMachines(session) {

    const [machines, setMachines] = useState([]);

    useEffect(() => {
        if (session) {
            axios.get(Const.API_MACHINES,{withCredentials: true}).then(res => {
                const data = res.data;
                console.log("machines", data)
                setMachines(data)
            }).catch(error => {
                console.log(JSON.stringify(error));
                setMachines([]);
            });
        }
    }, [session]);

    return machines;
}

export default useMachines
