import useStringFilter from "./useDictionaryFilter";
import {useMemo} from "react";


function useFilteredMachinesList(machines, machineStates, livePositions, filterString, sortDirection, showOnlyOnline, selectedField, highlightedMachines, selectedMachineName) {

    const listedMachines = useMemo( () => {
        return showOnlyOnline ? machines.filter(machine => machine.isOnline || machine.name === selectedMachineName || highlightedMachines.includes(machine.name)) : machines;
    }, [showOnlyOnline, machines, highlightedMachines]);

    const filteredMachines = useStringFilter(listedMachines, 'name', filterString);

    const machinesWithState = useMemo(() => {
        // console.log(JSON.stringify(machineStates));
        return filteredMachines && filteredMachines.map(m => {
            m.isKnown = !!machineStates[m.name];
            m.isOnline = m.isKnown && machineStates[m.name].status === 1;
            m.isPositionKnown = !!livePositions[m.name];
            return m;
        });
    }, [filteredMachines, livePositions, machineStates]);

    // noinspection UnnecessaryLocalVariableJS
    const sortedMachines = useMemo(() => {
        // const keys = machineFieldMapping.getMachineNames(selectedField);

        if (machinesWithState && highlightedMachines) {
            return machinesWithState.sort((a, b) => {

                return sortDirection * a.name.localeCompare(b.name);

                /*
                const aa = highlightedMachines.includes(a.name);
                const bb = highlightedMachines.includes(b.name);

                if (aa && !bb) {
                    return -1;
                } else if (!aa && bb) {
                    return 1;
                } else {
                    return sortDirection * a.name.localeCompare(b.name);
                }*/

                // return sortDirection * a.name.localeCompare(b.name);
            });
        } else {
            return machinesWithState;
        }
    }, [highlightedMachines, machinesWithState, sortDirection]);

    return sortedMachines;
    
}


export default useFilteredMachinesList;
