import React, { useState, useEffect } from 'react';
import * as Const from "../Const";

const SessionContext = React.createContext({});

function SessionContextProvider({children}) {
    const initialSession = localStorage.getItem(Const.SESSION_ID);
    const [session, setSession] = useState(initialSession);

    useEffect(() => {
        console.log("SESSION CONTEXT", session);
        if (session) {
            localStorage.setItem(Const.SESSION_ID, session);
        } else {
            localStorage.removeItem(Const.SESSION_ID);
        }
    }, [session]);

    const updateSession = (updatedValue) => {
        setSession(updatedValue);
    };

    // noinspection JSXUnresolvedComponent
    return (
        <SessionContext.Provider value={{session, updateSession}}>
            {children}
        </SessionContext.Provider>
    );
}

export {SessionContext, SessionContextProvider};
