

export function calculateAverage(coordinates) {
    if (coordinates.length === 0) {
        return null;
    }

    let sumX = 0;
    let sumY = 0;

    for (let i = 0; i < coordinates.length; i++) {
        const coordinate = coordinates[i];
        sumX += coordinate[0];
        sumY += coordinate[1];
    }

    const averageX = sumX / coordinates.length;
    const averageY = sumY / coordinates.length;

    return [averageX, averageY];
}



export function calculateBounds(coordinates) {
    if (coordinates.length === 0) {
        return null;
    }

    let minX = coordinates[0][0];
    let minY = coordinates[0][1];
    let maxX = coordinates[0][0];
    let maxY = coordinates[0][1];

    for (let i = 1; i < coordinates.length; i++) {
        const [x, y] = coordinates[i];
        minX = Math.min(minX, x);
        minY = Math.min(minY, y);
        maxX = Math.max(maxX, x);
        maxY = Math.max(maxY, y);
    }

    const min = [minX, minY];
    const max = [maxX, maxY];

    return { min, max };
}
