
class BidirectionalMapping {

    constructor() {
        this.machineToField = {};
        this.fieldToMachine = {};
    }

    add(machineName, fieldName) {
        // console.log("adding bidirectional", machineName, fieldName);
        if (!this.machineToField[machineName]) {
            this.machineToField[machineName] = [];
        }
        this.machineToField[machineName].push(fieldName);

        if (!this.fieldToMachine[fieldName]) {
            this.fieldToMachine[fieldName] = [];
        }
        this.fieldToMachine[fieldName].push(machineName);
    }

    removeByMachine(machineName) {
        if (this.machineToField[machineName]) {
            const fieldNames = this.machineToField[machineName];
            fieldNames.forEach((fieldName) => {
                this.fieldToMachine[fieldName].delete(machineName);
                if (this.fieldToMachine[fieldName].size === 0) {
                    delete this.fieldToMachine[fieldName];
                }
            });
            delete this.machineToField[machineName];
        }
    }

    removeByField(fieldName) {
        if (this.fieldToMachine[fieldName]) {
            const machineNames = this.fieldToMachine[fieldName];
            machineNames.forEach((machineName) => {
                this.machineToField[machineName].delete(fieldName);
                if (this.machineToField[machineName].size === 0) {
                    delete this.machineToField[machineName];
                }
            });
            delete this.fieldToMachine[fieldName];
        }
    }

    getFieldNames(machineName) {
        return this.machineToField[machineName] || [];
    }

    getMachineNames(fieldName) {
        console.log("get machine names", this.fieldToMachine[fieldName]);
        return this.fieldToMachine[fieldName] || [];
    }
}

export default BidirectionalMapping;
