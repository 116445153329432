import {useEffect, useState} from "react";


function useMachinesFilteredBySelectedField(machines, selectedField, machineFieldMapping) {

    const [filteredMachines, setFilteredMachines] = useState([]);

    useEffect(() => {
        if (selectedField && machineFieldMapping) {
            const keys = machineFieldMapping.getMachineNames(selectedField);
            const newMachines = machines.filter(m => keys.includes(m.name));
            setFilteredMachines(newMachines);
        } else {
            setFilteredMachines(machines);
        }

    }, [machines, selectedField, machineFieldMapping])

    return filteredMachines;

}

export default useMachinesFilteredBySelectedField;