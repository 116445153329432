import React from "react";
import { Outlet } from "react-router-dom";

const packageJson = require('../package.json');

function RootPage( {isEmbedded} ) {


    function closeDialog() {
        if (isEmbedded) {
            // eslint-disable-next-line no-undef
            RmAppJavaScriptInterface.closeDialog();
        }
    }


    const version = packageJson.version;

    return (
        <>
            <div className={"row justify-content-center"}>
                <div className={"col-auto"}>
                    {/*<img className={"w-100"} src={"/rm_inventory.svg"} alt={"RM Inventory"}/>*/}
                    <div style={{fontSize: 40}}>RM Cloud</div>
                </div>
            </div>
            <Outlet />
            <div>
                {isEmbedded
                    ? <button className={"btn btn-primary w-100"} onClick={closeDialog}>Close Window</button>
                    : <div>not embedded</div>
                }
            </div>
            <div>Version {version}</div>
        </>
    );
}

export default RootPage;
