import React from 'react';
import {X} from "react-bootstrap-icons";

function FilterInput({ filter, onFilterChanged }) {


    const handleFilterChange = (e) => {
        onFilterChanged(e.target.value);
    };

    return (
        <div className="input-group">
            <input type="text" className="form-control shadow-none" placeholder="Filter" aria-label="Filter" value={filter} onChange={handleFilterChange} />
            <button className="btn btn-secondary" type="reset" onClick={() => onFilterChanged('')} disabled={!filter}>{<X />}</button>
        </div>
    );
}

export default FilterInput;
