import {Button, Table} from "react-bootstrap";
import React, {useMemo, useState} from "react";
import {GeoAlt, GeoAltFill, LockFill, SortAlphaDown, SortAlphaUp, Unlock} from "react-bootstrap-icons";
import FilterInput from "./FilterInput";
import useFilteredMachinesList from "../hooks/useFilteredMachinesList";
import useMachinesFilteredBySelectedField from "../hooks/useMachinesFilteredBySelectedField";


function MachineRow( {machine, onMachineClicked, rowClass= ""} ) {
    return (
        <tr className={rowClass} onClick={() => onMachineClicked(machine.name)}>
            <td className={"text-center"}>
                {machine.isPositionKnown ? (machine.isOnline ? <GeoAltFill /> : <GeoAlt />) : null}
            </td>
            <td className={"overflow-hidden"}>
                <span className={"text-truncate"}>{machine.name}</span>
            </td>
        </tr>
    );
}


function OverviewMachineList( {machines, machineStates, livePositions, selectedMachineName, machineFilter, highlightedMachines, selectedField, onMachineClicked, onMachineFilterChanged} ) {

    const [showOnlyOnline, setShowOnlyOnline] = useState(false);
    const [sortDirection, setSortDirection] = useState(1);

    // const filteredMachines = useMachinesFilteredBySelectedField(machines, selectedField, machineFieldMapping);
    const displayedMachines = useFilteredMachinesList(machines, machineStates, livePositions, machineFilter, sortDirection, showOnlyOnline, selectedField, highlightedMachines, selectedMachineName);

    // const selectedMachines = useMemo(() => {
    //     return displayedMachines.filter(m => m.name === selectedMachineName);
    // }, [displayedMachines, selectedMachineName])


    return (
        <Table className={"table-sm w-100"}>
            <thead>
            <tr>
                <th></th>
                <th className={"w-100"}>
                    <Button className={"w-100 btn-light shadow-none"} onClick={() => setSortDirection(-sortDirection)}>
                        Machine {sortDirection > 0 ? <SortAlphaDown /> : <SortAlphaUp />}
                    </Button>
                </th>
            </tr>
            <tr>
                {/*<th></th>*/}
                <th>
                    <Button className={"flex-grow-0 btn-light shadow-none"} onClick={() => setShowOnlyOnline(!showOnlyOnline)}>
                        {showOnlyOnline ? <GeoAltFill /> : <GeoAlt />}
                    </Button>
                </th>
                <th>
                    <FilterInput filter={machineFilter} onFilterChanged={(filter) => onMachineFilterChanged(filter)} />
                </th>
            </tr>
            </thead>

            <tbody>
            {displayedMachines.map(m => {
                const rowClass = m.name === selectedMachineName
                    ? "table-primary"
                    : highlightedMachines && highlightedMachines.includes(m.name) ? "table-success" : 0;

                return <MachineRow key={m.name} machine={m} rowClass={rowClass} onMachineClicked={onMachineClicked}/>;
            })}

            {/*<Row>*/}
            {/*    <div>Version: {JSON.stringify(machineStates[VERSION_TAG])}</div>*/}
            {/*</Row>*/}
            </tbody>
        </Table>
    )

}


export default OverviewMachineList;
